<template>
  <div class="input-date field-wrapper label-animate" :class="fieldClass">
    <button class="input-date__trigger" aria-pressed="false" @click.prevent="showDateSelect"><span>Open calendar</span></button>
    <input :id="`field-${id}`" ref="field" v-model="input" :name="name" class="input-date__input" :class="inputClass" type="text" readonly>
    <label :for="`field-${id}`" class="animated-label">{{ label }}</label>

    <no-ssr>
      <!-- <modal
          :name="`date-select-${id}`"
          :overlay-transition="null"
          :click-to-close="false"
          height="100%"
          width="100%"
          class="date-modal"
          @before-open="beforeOpen"
      > -->
      <div v-if="!!isOpen" class="date-modal__inner-new">
        <!-- <button class="input-date__close" @click.prevent="closeCalendar"><Cross /></button> -->
        <datepicker
            v-model="date"
            :disabled-dates="disabledDates"
            :open-date="openDate"
            :monday-first="true"
            :inline="true"
            :highlighted="{
              from: sortedDateRange[0], // Highlight all dates after specific date
              to: sortedDateRange[1], // Highlight all dates up to specific date
              // a custom function that returns true of the date is highlighted
              // this can be used for wiring you own logic to highlight a date if none
              // of the above conditions serve your purpose
              // this function should accept a date and return true if is highlighted
              includeDisabled: true // Highlight disabled dates
            }"
            :day-cell-content="dayCellContent"
            name="uniquename"
            @selected="selectedContent"
        />
        <div v-if="!useInstantSelect" class="date-modal__footer-new">
          <span>{{ footerLabel }}</span>
          <button
              :disabled="disabledApply"
              class="btn--dark-green collective-community__button"
              @click.prevent="onClickApply"
          >
            {{ footerButtonLabel }} <Arrow class="btn--arrow__icon btn--arrow__icon--orange" />
          </button>
        </div>
      </div>
      <!-- </modal> -->
    </no-ssr>
  </div>
</template>

<script>
import Moment from 'moment';
import Datepicker from 'vue-inline-datepicker';
// import Cross from '~/assets/icons/cross.svg?inline';
// import { setTimeout } from 'timers';
import { trackArrivalDate } from '~/utils/gtm-events.js';

export default {
  components: {
    Datepicker,
    // Cross,
  },
  props: {
    useInstantSelect: { type: Boolean, required: false, default: false },
    footerButtonLabel: { type: String, required: false, default: 'Select' },
    footerLabel: { type: String, required: false, default: 'Please select a day to continue' },
    value: { type: String, required: false, default: null },
    label: { type: String, required: true },
    name: { type: String, required: true },
    disabledDates: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
    allowRange: { type: Boolean, required: false, default: false },
    openDate: { type: Date, required: false, default: null },
    focus: { type: Function, required: false, default: () => 1 },
    selectDates: { type: Function, required: false, default: () => 1 },
  },
  data() {
    return {
      dateRange: [],
      sortedDateRange: [],
      selectedDate: null,
      date: null,
      input: '',
      displayDate: '',
      hasValue: false,
      id: null,
      isOpen: false,
    };
  },
  computed: {
    inputClass() {
      return {
        'has-value': this.hasValue,
      };
    },
    fieldClass() {
      return { disabled: this.disabled };
    },
    disabledApply() {
      return this.date == null;
    },
  },
  watch: {
    allowRange(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.reset();
      }
    },
  },
  mounted() {
    this.id = this.name || this._uid;
  },
  methods: {
    reset() {
      this.input='';
      this.date=null;
      this.dateRange= [];
      this.sortedDateRange= [];
      this.hasValue = false;
      this.closeCalendar();
    },
    onClickApply() {
      this.input = this.displayDate;
      const fldate = Moment(this.date).format('YYYY-MM-DD');
      trackArrivalDate(this.$gtm, this.input, fldate);
      this.hasValue = this.input.length > 0;
      this.$modal.hide(`date-select-${this.id}`);

      this.date = null;
      this.$emit('input', this.input);

      this.isOpen = false;
    },
    beforeOpen() {
      this.date = this.selectedDate;

      const e = { target: { name: this.name } }; // simulate input focus
      this.focus(e);
    },
    dayCellContent(x) {
      return `<div>${x.date}</div>`;
    },
    selectedContent(selectedDate) {
      this.sortedDateRange = [];

      if (this.allowRange) {
        this.dateRange.push(selectedDate);
        if (this.dateRange.length>2) {
          this.dateRange.shift();
        }

        this.sortedDateRange = [...this.dateRange].sort((a, b) => (a-b));
        const startDate = this.sortedDateRange[0] && Moment(this.sortedDateRange[0]).format('Do MMM Y');
        const endDate = this.sortedDateRange[1] && Moment(this.sortedDateRange[1]).format('Do MMM Y');

        this.displayDate = endDate ? `${startDate} - ${endDate}`: `${startDate} -`;

      } else {
        this.displayDate = Moment(selectedDate).format('Do MMM Y');
      }
      this.input = this.displayDate;
      this.hasValue = this.input.length > 0;
      this.$emit('input', this.input);
      this.useInstantSelect && this.onClickApply();
    },
    showDateSelect() {
      this.isOpen=true;
      if (!this.disabled) {
        if (document.querySelector('.date-modal')) {
          const closeId = document.querySelector('.date-modal').getAttribute('data-modal');
          this.$modal.hide(closeId);
        }

        this.$modal.show(`date-select-${this.id}`);
        // setTimeout(function() {
        //   document.querySelector('.input-date__close').focus();
        // }, 100);
      }
    },
    closeCalendar() {
      this.isOpen=false;
      this.$modal.hide('date-select-MOVEINDATE');
      // this.$el.querySelector('.input-date__trigger').focus();
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/elements/_input-date.scss';
</style>
