var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-date field-wrapper label-animate",class:_vm.fieldClass},[_c('button',{staticClass:"input-date__trigger",attrs:{"aria-pressed":"false"},on:{"click":function($event){$event.preventDefault();return _vm.showDateSelect.apply(null, arguments)}}},[_c('span',[_vm._v("Open calendar")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],ref:"field",staticClass:"input-date__input",class:_vm.inputClass,attrs:{"id":("field-" + _vm.id),"name":_vm.name,"type":"text","readonly":""},domProps:{"value":(_vm.input)},on:{"input":function($event){if($event.target.composing){ return; }_vm.input=$event.target.value}}}),_vm._v(" "),_c('label',{staticClass:"animated-label",attrs:{"for":("field-" + _vm.id)}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('no-ssr',[(!!_vm.isOpen)?_c('div',{staticClass:"date-modal__inner-new"},[_c('datepicker',{attrs:{"disabled-dates":_vm.disabledDates,"open-date":_vm.openDate,"monday-first":true,"inline":true,"highlighted":{
            from: _vm.sortedDateRange[0], // Highlight all dates after specific date
            to: _vm.sortedDateRange[1], // Highlight all dates up to specific date
            // a custom function that returns true of the date is highlighted
            // this can be used for wiring you own logic to highlight a date if none
            // of the above conditions serve your purpose
            // this function should accept a date and return true if is highlighted
            includeDisabled: true // Highlight disabled dates
          },"day-cell-content":_vm.dayCellContent,"name":"uniquename"},on:{"selected":_vm.selectedContent},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_vm._v(" "),(!_vm.useInstantSelect)?_c('div',{staticClass:"date-modal__footer-new"},[_c('span',[_vm._v(_vm._s(_vm.footerLabel))]),_vm._v(" "),_c('button',{staticClass:"btn--dark-green collective-community__button",attrs:{"disabled":_vm.disabledApply},on:{"click":function($event){$event.preventDefault();return _vm.onClickApply.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.footerButtonLabel)+" "),_c('Arrow',{staticClass:"btn--arrow__icon btn--arrow__icon--orange"})],1)]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }