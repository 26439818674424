<template>
  <div class="field-wrapper label-animate">
    <input
        :id="`field-${id}`"
        ref="field"
        v-model="input"
        v-validate="validate"
        :autocomplete="autocompleteValue"
        :name="name"
        :type="type"
        :class="inputClass"
        :placeholder="placeholder"
        maxlength="255"
        @blur="blurValidate"
        @keyup="onInput"
        @focus="focus"
        @change="debouncedChangeFn"
        @input="inputFn"
    >
    <label :for="`field-${id}`" class="animated-label">{{ label }}</label>
    <span v-if="error" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { captureUserBudget } from '~/utils/gtm-events.js';
import debounce from 'lodash.debounce';

export default {
  props: {
    value: { type: String, required: false, default: null },
    validate: { type: [String, Object], required: false, default: null },
    label: { type: String, required: true },
    errorMessage: { type: String, required: true },
    autocompleteValue: { type: String, required: true },
    placeholder: { type: String, required: false, default: '' },
    type: { type: String, required: true },
    name: { type: String, required: true },
    focus: { type: Function, required: false, default: () => 1 },
    blur: { type: Function, required: false, default: () => 1 },
    change: { type: Function, required: false, default: () => {} },
    inputFn: { type: Function, required: false, default: () => {} },
    customError: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      input: this.value,
      error: false,
      hasValue: false,
      id: null,
    };
  },
  computed: {
    inputClass() {
      return {
        'error': this.error,
        'has-value': this.hasValue,
      };
    },
  },
  watch: {
    customError(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.error = newValue;
      }
    },
  },
  mounted() {
    this.id = this._uid;
    if (this.$vnode.elm.classList.contains('newsletter-signup__email')) {
      this.id = 'email';
    }
    this.hasValue = this.value && this.value.length > 0;
    this.debouncedChangeFn = debounce(this.changeFn, 100);
  },
  methods: {
    blurValidate(e) {
      this.error = this.fields[this.name] && !this.fields[this.name].valid;
      if (this.name === 'MONTHLYBUDGET') captureUserBudget(this.$gtm, this.value);
      this.blur(e);
    },
    isValid() {
      const { valid } = this.fields[this.name] || true;
      return valid;
    },
    debouncedChangeFn(e) {},

    changeFn(e) {
      this.onInput(e);
      this.blurValidate(e);
      this.change(e);
    },
    onInput(event) {
      const { value } = event.target;
      this.hasValue = value.length > 0;
      this.$emit('input', this.input);
    },
  },
};
</script>
